import React, { useEffect, useRef, useState } from "react";
import { Axios } from "../config/axios";
import {
  GET_IMAGE,
  GET_COORDINATES,
  UPDATE_VERDICT,
  UPDATE_EXPLANATION,
} from "../constants";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import toasted from "toasted-notes";
import moment from "moment";
import { useAuthState } from "../context/auth";

export const Img = (props) => {
  const id = props.match.params.id;
  const [image, setImage] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const [update, setUpdate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [newList, setNewList] = useState([]);
  const [cs, setCs] = useState([]);
  const [backup, setBackup] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const { user } = useAuthState();

  const getImage = () => {
    Axios.get(GET_IMAGE + id)
      .then((res) => {
        console.log("Imagen -> ", res.data.data);
        setImage(res.data.data);
        const img = res.data.data;
        const cs = img.coordinates;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        setUrlImage(img.url);
        var imgPath = img.url;
        var image = new Image();
        image.src = imgPath;
        let mydict = {};

        cs.forEach((c) => {
          if (typeof mydict[c.className] === "undefined")
            mydict[c.className] =
              "#" + Math.floor(Math.random() * 16777215).toString(16);
        });

        setCs(cs);
        setBackup(cs);

        image.onload = function () {
          ctx.drawImage(image, 0, 0);
          cs.forEach((c) => {
            const ymin = c.ymin;
            const ymax = c.ymax;
            const xmin = c.xmin;
            const xmax = c.xmax;
            ctx.beginPath();
            ctx.lineWidth = "5";
            ctx.strokeStyle = mydict[c.className];
            ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
            //ctx.setLineDash([2, 5, 5, 15]);
            ctx.fillStyle = mydict[c.className];
            ctx.font = "bold 10pt sans-serif";
            ctx.fillText(c.className, xmin, ymin - 2);
            ctx.stroke();
          });
        };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteObject = (c) => {
    let newCs = cs;
    newCs = newCs.filter((x) => x.className != c.id);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let image = new Image();
    image.src = urlImage;

    let mydict = {};

    newCs.forEach((c) => {
      if (typeof mydict[c.className] === "undefined")
        mydict[c.className] =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
    });

    image.onload = function () {
      ctx.drawImage(image, 0, 0);
      newCs.forEach((c) => {
        const ymin = c.ymin;
        const ymax = c.ymax;
        const xmin = c.xmin;
        const xmax = c.xmax;
        ctx.beginPath();
        ctx.lineWidth = "5";
        ctx.strokeStyle = mydict[c.className];
        ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
        //ctx.setLineDash([2, 5, 5, 15]);
        ctx.fillStyle = mydict[c.className];
        ctx.font = "bold 10pt sans-serif";
        ctx.fillText(c.className, xmin, ymin - 2);
        ctx.stroke();
      });
    };
    setCs([...newCs]);
  };

  const drawObject = (c) => {
    let newCs = cs;
    console.log(newCs);
    let recovery = backup.filter((x) => x.className == c.id);
    console.log(recovery);
    recovery.forEach((x) => {
      newCs.push(x);
    });
    newCs.push();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let image = new Image();
    image.src = urlImage;

    let mydict = {};

    newCs.forEach((c) => {
      if (typeof mydict[c.className] === "undefined")
        mydict[c.className] =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
    });

    image.onload = function () {
      ctx.drawImage(image, 0, 0);
      newCs.forEach((c) => {
        const ymin = c.ymin;
        const ymax = c.ymax;
        const xmin = c.xmin;
        const xmax = c.xmax;
        ctx.beginPath();
        ctx.lineWidth = "5";
        ctx.strokeStyle = mydict[c.className];
        ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
        //ctx.setLineDash([2, 5, 5, 15]);
        ctx.fillStyle = mydict[c.className];
        ctx.font = "bold 10pt sans-serif";
        ctx.fillText(c.className, xmin, ymin - 2);
        ctx.stroke();
      });
    };
    setCs([...newCs]);
  };

  const getCoordinates = () => {
    Axios.get(GET_COORDINATES + id)
      .then((res) => {
        let data = res.data.data;
        const newlist = data;

        setNewList(newlist);

        data = data.map((d) => {
          return {
            ...d,
            select: true,
          };
        });
        console.log(data);
        setCoordinates(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getImage();
    getCoordinates();
  }, [update]);

  const updateVerdict = () => {
    // console.log(image);
    const data = {
      IdImage: image.id,
      IdUser: user.id,
      Update: image.verdict ? false : true,
    };
    // console.log(data, "jolassasad");
    Axios.put(`${UPDATE_VERDICT}${image.id}`, data)
      .then((res) => {
        toasted.notify(res.data.message);
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateExplanation = () => {
    console.log(explanation);
    Axios.put(UPDATE_EXPLANATION, {
      id: image.id,
      explanation,
    })
      .then((res) => {
        toasted.notify(res.data.message);
        setUpdate(!update);
        setEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTotal = () => {
    let total = 0;
    coordinates.forEach((x) => {
      total += x.count;
    });

    return total;
  };

  return (
    <Container className="py-5">
      <Row className="mb-2">
        <Button variant="primary" onClick={() => props.history.push(`/`)}>
          Ver imagenes
        </Button>
      </Row>
      <Row>
        <Col sm={6} className="py-2">
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Porcentaje</th>
                <th>Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {coordinates.map((c, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{c.id}</td>
                  <td className="text-center">{c.count}</td>
                  <td className="text-center">
                    {((c.count * 100) / handleTotal()).toFixed(1) + " %"}
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={c.select}
                      onClick={(e) => {
                        let change = e.target.checked;
                        console.log(change);
                        coordinates.map((co) => {
                          if (co.id == c.id) {
                            co.select = e.target.checked;
                          }
                          return co;
                        });
                        setCoordinates([...coordinates]);
                        if (!change) {
                          deleteObject(c);
                        } else {
                          drawObject(c);
                        }
                      }}
                      data-toggle="toggle"
                      data-size="sm"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td className="text-center">Total</td>
                <td className="text-center">{handleTotal()}</td>
                <td className="text-center">{"100%"}</td>
              </tr>
            </tbody>
          </Table>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Elemento</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {newList.map((c, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{c.id}</td>
                  <td className="text-center">{c.count}</td>
                </tr>
              ))}
              {/* <tr>
                <td></td>
                <td className="text-center">Total</td>
                <td className="text-center">{newList && handleTotal()}</td>
              </tr> */}
            </tbody>
          </Table>
          <Row>
            <Col sm={12} className="p-3">
              <Card className="p-3">
                <Card.Header>
                  <h4>Información del cliente</h4>
                </Card.Header>
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Row>
                    <p className="h5 font-weight-bold">
                      Fecha de captura:{" "}
                      <span className="ml-2 font-weight-normal">
                        {moment(image.date).format("D/MM/YYYY, h:mm a")}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h5 font-weight-bold">
                      Codigo del cliente:{" "}
                      <span className="ml-2 font-weight-normal">
                        {image.clientId}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h5 font-weight-bold">
                      Codigo regional:{" "}
                      <span className="ml-2 font-weight-normal">
                        {image.wareHouse}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h5 font-weight-bold">
                      Codigo de la tienda:{" "}
                      <span className="ml-2 font-weight-normal">
                        {image.storeCode}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h5 font-weight-bold">
                      Codigo del vendedor:{" "}
                      <span className="ml-2 font-weight-normal">
                        {image.vendorCode}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h5 font-weight-bold">
                      Nombre de la tienda:{" "}
                      <span className="ml-2 font-weight-normal">
                        {image.storeName}
                      </span>
                    </p>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="row mb-3">
            <div className="col-sm">
              <p>
                <h4>Veredicto:</h4> {image.verdict ? "Verdadero" : "Falso"}
              </p>
            </div>
            <div className="col-sm">
              <button onClick={updateVerdict} className="btn btn-primary">
                Cambiar Veredicto
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-9">
              {edit ? (
                <>
                  <h4>Explicación:</h4>
                  <div class="form-group">
                    <textarea
                      class="form-control rounded-0"
                      id="exampleFormControlTextarea1"
                      rows="10"
                      defaultValue={explanation}
                      onChange={(e) => setExplanation(e.target.value)}
                    ></textarea>
                  </div>
                </>
              ) : (
                <p>
                  {" "}
                  <h4>Explicación:</h4>
                  {image.explanation}
                </p>
              )}
            </div>
            <div className="col-sm-3">
              {edit ? (
                <>
                  <button
                    onClick={updateExplanation}
                    className="btn btn-primary mb-3"
                  >
                    Guardar
                  </button>
                  <button
                    onClick={() => {
                      setEdit(false);
                    }}
                    className="btn btn-primary"
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setEdit(true);
                    setExplanation(image.explanation);
                  }}
                  className="btn btn-primary"
                >
                  Editar
                </button>
              )}
            </div>
          </div>
          {/* <p>
            <h4>Arbol:</h4>{" "}
            {image.arbol === null
              ? "SIN VALIDAR"
              : image.arbol
              ? "Verdadero"
              : "Falso"}
          </p> */}
          {/* <p>
            <h4>Filas:</h4> {image.filas}
          </p> */}
        </Col>
        <Col sm={6} className="py-2">
          <canvas
            id="myCanvas"
            width="1024"
            height="1024"
            style={{ border: "1px solid #d3d3d3" }}
            ref={canvasRef}
            {...props}
          />
        </Col>
      </Row>
    </Container>
  );
};
