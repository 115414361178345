import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Images } from "./components/Images";
import { Img } from "./components/Img";
import { AuthProvider } from "./context/auth";
import DynamicRoute from "./utils/DynamicRoute";
import Login from "./components/auth/Login"
import "toasted-notes/src/styles.css"; // optional styles

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"
import Dashboard from "./components/Admin/Dashboard/Dashboard";
function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
        <Switch>
            <DynamicRoute exact path="/dashboard" component={Dashboard} authenticated />
            <DynamicRoute path="/login" component={Login} guest />
            <DynamicRoute exact path="/imagenes" component={Images} authenticated />
            <DynamicRoute exact path="/:id" component={Img} authenticated />
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
