import React, { useEffect, useState } from "react";
import { Axios } from "../config/axios";
// import { Link } from "react-router-dom";
import { GET_IMAGES, GET_IMAGES_FILTER, GET_SEASONS } from "../constants";
import { Container, Row, Col, Card, Image, Button, Nav } from "react-bootstrap";
import { useAuthState } from "../context/auth";
// import cumple from "../assets/icons/comprobar.svg";
// import nocumple from "../assets/icons/cerrar.svg";

import moment from "moment";
import { Pagination } from "@material-ui/lab";
import Select from "react-dropdown-select";

export const Images = ({ history }) => {
  // const [images, setImages] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  // const [backup, setBackup] = useState([]);
  const [route, setRoute] = useState(0);
  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage, setImagesPerPage] = useState(20);

  // const authDispatch = useAuthDispatch();
  const { user } = useAuthState();

  const [input, setInput] = useState("");
  const [codFilter, setCodFilter] = useState(-1);
  const [load, setLoad] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState(0);
  // const [verdict, setVerdict] = useState("");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    console.log(user.regional);
    console.log(route);
    getSeasons();
  }, []);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0].id;
        setSeason(season);
        setSeasons(res.data.data.filter(temp => temp.active === 1));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //page es el id de la season
  const getImages = (region, page, verdict = 0) => {
    setLoad(true);
    setPlaceholder("");
    setInput("");

    let url = `${GET_IMAGES}${season}?region=${region}&page=${page}&pageSize=${imagesPerPage}&verdict=${verdict}`;
    console.log(url);
    Axios.get(url)
      .then((res) => {
        console.log(res.data.data);
        setCurrentImages(res.data.data.images);
        setTotal(res.data.data.total);
        // setImages(res.data.data.images);
        // setBackup(res.data.data.images);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const handleFilter = (route, type) => {
    // setRoute(route);
    // if (type === null) {
    //   setCurrentImages(backup);
    // } else {
    //   const newImages = backup.filter((i) => i.verdict === type);
    //   setCurrentImages(newImages);
    // }

    setRoute(route);
    localStorage.setItem("verdict", route);

    if (codFilter === 0) {
      getImages(user.regional, currentPage, route);
      window.scrollTo(0, 0);
    } else {
      handleQuery(currentPage, route);
      window.scrollTo(0, 0);
    }
  };

  // const logout = () => {
  //   authDispatch({ type: "LOGOUT" });
  // };

  const handleChange = (event, value) => {
    setCurrentImages([]);
    //setRoute(0);
    setCurrentPage(value);

    if (codFilter === 0) {
      getImages(user.regional, value, route);
      window.scrollTo(0, 0);
    } else {
      handleQuery(value, route);
      window.scrollTo(0, 0);
    }
  };

  const filter = (value) => {
    console.log(value);
    console.log(codFilter);
    if (codFilter === -1 || codFilter === 0) {
      return;
    }
    setInput(value);
  };

  const onChangeCod = (c, p) => {
    setCurrentPage(1);
    setRoute(0);
    setInput("");
    setCurrentImages([]);
    setCodFilter(c);
    setPlaceholder(p);
  };

  const handleQuery = (page, verdict = 0) => {
    // if (
    //   codFilter === -1 ||
    //   codFilter === 0 ||
    //   input.length === 0 ||
    //   (codFilter === 1 && input.length != 7) ||
    //   (codFilter === 2 && input.length != 10) ||
    //   (codFilter === 3 && input.length != 4)
    // )
    // return;
    // console.log(codFilter);
    // console.log(input);
    setLoad(true);
    let url = `${GET_IMAGES_FILTER}${season}?region=${user.regional}&cod=${codFilter}&query=${input}&page=${page}&pageSize=${imagesPerPage}&verdict=${verdict}`;
    console.log(url);
    Axios.get(url)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        setTotal(data.total);
        // setImages(data.images);
        // setBackup(data.images);
        setCurrentImages(data.images);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  return (
    <div>
      <Container className="p-5">
        <div className="row align-items-start">
          <div className="col-sm-4">
            <Select
              style={{
                borderRadius: "7px",
                height: "55px",
              }}
              color="#85C1E9"
              options={seasons}
              labelField="name"
              valueField="id"
              onChange={(value) => {
                console.log(value[0]);
                const selectedSeason = value[0];
                setSeason(selectedSeason.id);
                setSelected(true);
                setCodFilter(-1);
                setInput("");
                setPlaceholder("");
                setCurrentImages([]);
                getImages(user.regional, selectedSeason.id);
              }}
              values={[{ id: -1, name: "Seleccione una temporada" }]}
            />
          </div>

          {selected && (
            <div className="col-sm-7">
              <div className="row align-items-center">
                <div className="col-sm-10">
                  <input
                    disabled={codFilter === 0 || codFilter === -1 ? true : false}
                    className="form-control"
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => filter(e.target.value)}
                    value={input}
                  />
                </div>
                <div className="col-sm-1">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => handleQuery(1)}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="m-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    checked={codFilter === 1}
                    onClick={() => onChangeCod(1, "CODIGO VENDEDOR")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    COD VENDEDOR
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio3"
                    checked={codFilter === 2}
                    onClick={() => onChangeCod(2, "CODIGO CLIENTE")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    COD CLIENTE
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="col-sm-1">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => (window.location.href = "/dashboard")}
            >
              Dashboard
            </button>
          </div>
        </div>

        {load && (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border spinner-border-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {currentImages.length > 0 && (
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link
                className={
                  route === 0
                    ? "bg-primary text-white h5"
                    : "bg-white text-primary h5"
                }
                onClick={() => handleFilter(0, null)}
              >
                Imagenes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={
                  route === 1
                    ? "bg-success text-white h5"
                    : "bg-white text-success h5"
                }
                onClick={() => handleFilter(1, true)}
              >
                Cumplen
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={
                  route === 2
                    ? "bg-danger text-white h5"
                    : "bg-white text-danger h5"
                }
                onClick={() => handleFilter(2, false)}
              >
                No cumplen
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                className="bg-white text-primary h4"
                onClick={() => (window.location.href = "/Dashboard")}
              >
                Dashboard
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        )}
        {currentImages.length > 0 && (
          <>
            <Row>
              {currentImages.map((img, i) => (
                <Col key={i} sm={4} className="p-2">
                  <Card className="p-3">
                    <Image
                      style={{ cursor: "pointer" }}
                      onClick={() => window.open(`/${img.id}`, "_blank")}
                      src={img.url}
                      fluid
                    />

                    <Card.Body className="d-flex flex-column justify-content-center">
                      <Row>
                        <p className="h6 font-weight-bold">
                          Fecha de captura:{" "}
                          <span className="ml-2 font-weight-normal">
                            {moment(img.date).format("D/MM/YYYY, h:mm a")}
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <p className="h6 font-weight-bold">
                          Codigo del cliente:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.clientId}
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <p className="h6 font-weight-bold">
                          Codigo regional:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.wareHouse}
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <p className="h6 font-weight-bold">
                          Codigo del vendedor:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.vendorCode}
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <p className="h6 font-weight-bold">
                          Nombre del cedi:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.cediName}
                          </span>
                        </p>
                      </Row>
                      <Row>
                        <p className="h6 font-weight-bold">
                          Nombre de la tienda:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.storeName}
                          </span>
                        </p>
                      </Row>
                      {/* <Row>
                        <p className="h6 font-weight-bold">
                          Arbol:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.arbol ==== null
                              ? "SIN VALIDAR"
                              : img.arbol
                              ? "Verdadero"
                              : "Falso"}
                          </span>
                        </p>
                      </Row> */}
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        variant="primary"
                        onClick={() => window.open(`/${img.id}`, "_blank")}
                      >
                        Ver Detalles
                      </Button>
                      {/* <img
                        className="ml-2 mt-1"
                        src={img.verdict ? cumple : nocumple}
                        width="30"
                        height="30"
                      /> */}
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-center mt-5">
              <Pagination
                siblingCount={2}
                size="large"
                variant="outlined"
                color="primary"
                count={Math.ceil(total / imagesPerPage)}
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
