import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import {
  ExitToApp,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import logo from "../../../assets/images/cel.png";
import { useAuthDispatch, useAuthState } from "../../../context/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  colorText: {
    color: "#707070",
  },
}));

export const MainListItems = ({ handleRoute }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const { user } = useAuthState();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={() => handleRoute(0)}>
        <ListItemIcon className={classes.colorText}>
          <PermMediaIcon />
        </ListItemIcon>
        <ListItemText primary="Imagenes" className={classes.colorText} />
      </ListItem>
    </div>
  );
};

export const SecondaryListItems = ({ open }) => {
  console.log(open);
  const authDispatch = useAuthDispatch();
  return (
    <div>
      <ListItem
        button
        onClick={() => {
          authDispatch({ type: "LOGOUT" });
        }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
      {open && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "90px",
          }}
        >
          {/* <img src={logo} width="90" height="18" style={{ margin: "1em" }} /> */}
        </div>
      )}
    </div>
  );
};
